import React, { useMemo } from 'react';

import cx from 'classnames';

import { Row, Col, Container } from 'design-react-kit/dist/design-react-kit';

import AlphabetNavbar from '../../AlphabetNavbar/AlphabetNavbar';
import ResultCard from '../../ResultCard/ResultCard';
import UOFilter from './UOFilter';

const RicercaAlfabeticaTemplate = (props) => {
  const {
    title,
    show_block_bg,
    addFilters,
    items,
    additionalFilters = [],
    uo_filter = false,
  } = props;
  let filterLetter = additionalFilters
    .filter((filter) => {
      return filter.i === 'startswith';
    })
    ?.map((filter) => {
      return filter.v;
    });

  const allFilters = additionalFilters.filter((el) => el.i !== 'startswith');

  const onClickFilter = (letter) => {
    addFilters([
      ...allFilters,
      {
        i: 'startswith',
        o: 'plone.app.querystring.operation.string.is',
        v: letter,
      },
    ]);
  };

  const resetLetterFilter = () => {
    addFilters([...allFilters]);
  };

  const allItemsArePerson = useMemo(
    () => items.every((el) => el['@type'] === 'Persona'),
    [items],
  );
  return (
    <div className="blocco-alfabeto">
      <Container className="px-4">
        {title && (
          <Row className="template header">
            {title && (
              <Col md="12">
                <h2
                  className={cx('mb-4', {
                    'mt-5': !show_block_bg,
                  })}
                >
                  {title}
                </h2>
              </Col>
            )}
          </Row>
        )}

        <div
          className={cx('alphabet-navbar-section', {
            'mb-5': !uo_filter,
            'mb-3': uo_filter,
          })}
        >
          <AlphabetNavbar
            onClickHandler={onClickFilter}
            filterLetter={filterLetter[0]}
            resetLetterFilter={resetLetterFilter}
          />
        </div>

        {/* Filter by UO only shows up if it's CT person */}
        {uo_filter && (
          <UOFilter
            addFilters={addFilters}
            additionalFilters={additionalFilters}
          />
        )}

        <Row className="items">
          <div className="result-card-container">
            <ResultCard
              searchResults={items}
              allItemsArePerson={allItemsArePerson}
            />
          </div>
          {items.length === 0 && (
            <div className="no-results">Nessun risultato</div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default RicercaAlfabeticaTemplate;
