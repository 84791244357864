import {
  templatesOptions,
  addDefaultOptions,
  addSchemaField,
} from 'design-comuni-plone-theme/config/Blocks/ListingOptions';

export const AddRicercaAlfabeticaTemplate = (
  schema,
  formData,
  position = 0,
  intl,
) => {
  let pos = position;

  if (
    formData?.querystring?.query?.some(
      (q) =>
        q?.v !== '' &&
        q?.i === 'portal_type' &&
        q?.v?.every((v) => v === 'Persona'),
    )
  ) {
    addSchemaField(
      schema,
      'uo_filter',
      'Filtro per UO',
      'Aggiungi filtro per struttura. Questo checkbox mostra un menu a tendina da cui gli utenti potranno filtrare i risultati per struttura',
      { type: 'boolean', default: false },
      2,
      'default',
    );
    pos++;
  } else {
    formData.uo_filter = null;
    pos = addDefaultOptions(schema, formData, pos);
    pos = templatesOptions(schema, formData, intl, [], {}, pos);
  }

  return pos;
};
