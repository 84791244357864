import React from 'react';

import { defineMessages, useIntl } from 'react-intl';

import { UniversalLink } from '@plone/volto/components';

import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardReadMore,
} from 'design-react-kit/dist/design-react-kit';

import CardPersonaGalliera from './CardPersonaGalliera';

const ResultCard = ({ searchResults, allItemsArePerson = false }) => {
  const intl = useIntl();
  return (
    <div className="card-container row">
      {searchResults.map((item) => {
        const { title, description } = item;

        return (
          <div
            className={`single-card-container ${
              allItemsArePerson
                ? 'col-12'
                : 'col-lg-4 col-md-6 col-sm-12 small-card'
            }`}
            key={item['@id']}
          >
            <Card className={allItemsArePerson ? 'card-persona' : ''}>
              <CardBody tag="div">
                {!allItemsArePerson ? (
                  <>
                    <CardTitle tag="h4">{title}</CardTitle>
                    <CardText title={description}>
                      {description.slice(0, 140)}
                      {description.length > 140 ? '…' : ''}
                    </CardText>
                    <CardReadMore
                      tag={UniversalLink}
                      text={intl.formatMessage(messages.leggiLink)}
                      iconName="it-arrow-right"
                      href={item['@id']}
                    />
                  </>
                ) : (
                  <CardPersonaGalliera item={item} title={title} />
                )}
              </CardBody>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default ResultCard;

const messages = defineMessages({
  leggiLink: {
    id: 'leggiLink',
    defaultMessage: 'Leggi',
  },
  prenota: {
    id: 'prenota',
    defaultMessage: 'Prenota',
  },
  esami_ssn: {
    id: 'esami_ssn',
    defaultMessage: 'Visite ed esami SSN',
  },
  libera_professione: {
    id: 'libera_professione',
    defaultMessage: 'Libera professione',
  },
});
