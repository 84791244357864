import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getVocabulary } from '@plone/volto/actions';

const messages = defineMessages({
  persone_struttura: {
    id: 'persone_struttura',
    defaultMessage: 'Persone',
  },
});

const UOPeople = ({ content }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // create object Ruolo: Persone
  let profiles = content?.persone_struttura?.reduce((r, a) => {
    const profile = a.profilo_professionale ?? '';
    const person_info = {
      name: a.title,
      incarico: a.incarico_rivestito,
      link: a['@id'],
    };

    r[profile] = r[profile] || [];
    r[profile].push(person_info);

    return r;
  }, Object.create(null));

  const ProfiloProfessionaleVocabulary = useSelector(
    (state) =>
      state.vocabularies[
        'design.plone.contenttypes.ProfiloProfessionalePersona'
      ]?.items ?? [],
  ).sort((a, b) => {
    if (a.value === '') return 1;
    if (b.value === '') return -1;
    return 0;
  });

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'design.plone.contenttypes.ProfiloProfessionalePersona',
        size: 1000,
      }),
    );
  }, [dispatch]);

  return content?.persone_struttura?.length > 0 ? (
    <article
      id="persone-struttura"
      className="it-page-section anchor-offset mt-5"
      aria-labelledby="header-persone-struttura"
    >
      <h4 id="header-persone-struttura">
        {intl.formatMessage(messages.persone_struttura)}
      </h4>
      <div className="persone-table">
        {ProfiloProfessionaleVocabulary.map((el, index) => {
          return profiles[el.value] ? (
            <div className="row">
              <div className="col col-sm-12 col-md-3 profilo">
                {el.value !== '' ? el.value : '---'}
              </div>
              <div className="col col-sm-12 col-md-9 name px-0">
                {profiles[el.value]
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((person) => {
                    return (
                      <div className="row profiles">
                        <div className="col profile-col">
                          <Link to={flattenToAppURL(person.link)}>
                            {person.name}
                          </Link>
                        </div>

                        <div className="col profile-col">
                          {person.incarico ? person.incarico : '---'}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null;
        })}
      </div>
    </article>
  ) : null;
};

export default UOPeople;
