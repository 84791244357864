import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import { getVocabulary } from '@plone/volto/actions';
import { SelectInput } from 'design-comuni-plone-theme/components';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const UOFilter = ({ addFilters, additionalFilters }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const allFilters = additionalFilters.filter(
    (el) => el.i !== 'organizzazione_reparto',
  );

  let filterOrganizzazione = additionalFilters
    .filter((filter) => {
      return filter.i === 'organizzazione_reparto';
    })
    ?.map((filter) => {
      return filter.v;
    });

  // Filtro per UO - disponibile solo per CT persona
  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'ospedaligalliera.policy.organizzazione_reparto',
        size: 1000,
      }),
    );
  }, [dispatch]);

  const uoList = useSelector(
    (state) =>
      state.vocabularies['ospedaligalliera.policy.organizzazione_reparto']
        ?.items ?? [],
  );

  const setFilters = (opt) => {
    addFilters([
      ...allFilters,
      {
        i: 'organizzazione_reparto',
        o: 'plone.app.querystring.operation.selection.any',
        v: opt.value,
      },
    ]);
  };

  const resetUoFilter = () => {
    addFilters([...allFilters]);
  };

  const ClearButton = (props) => {
    return (
      <button onClick={resetUoFilter}>
        <Icon icon="it-close" style={{ ariaHidden: true }} />
      </button>
    );
  };

  const selectedValue = uoList.filter((el) => {
    return el.value === filterOrganizzazione[0];
  });

  return (
    <div className="uo-filter-section mb-3">
      <div className="filter-wrapper col-lg-4 col-md-6 col-sm-12">
        <SelectInput
          id="organizzazione_reparto"
          placeholder={intl.formatMessage(messages.filter_by_uo)}
          onChange={setFilters}
          value={selectedValue}
          options={uoList}
          isClearable={true}
          isSearchable={true}
          clearValue={() => addFilters([])}
          components={{
            ClearIndicator: (props) => {
              return <ClearButton />;
            },
          }}
        />
      </div>
    </div>
  );
};

const messages = defineMessages({
  filter_by_uo: {
    id: 'filter_by_uo',
    defaultMessage: 'Filtra per struttura',
  },
});

export default UOFilter;
