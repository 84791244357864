import React from 'react';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';

import {
  CardTitle,
  CardText,
  Chip,
  ChipLabel,
} from 'design-react-kit/dist/design-react-kit';
import { UniversalLink } from '@plone/volto/components';
import {
  ListingImage,
  Icon,
} from 'design-comuni-plone-theme/components/ItaliaTheme';

const CardPersonaGalliera = ({ item, title }) => {
  const intl = useIntl();
  const fine_incarico = item.data_conclusione_incarico
    ? moment(item.data_conclusione_incarico).format('DD/MM/YYYY')
    : null;
  const image = ListingImage({ item, showTitleAttr: false });

  return (
    <div className="person-card-content mb-2">
      <div className="col-lg-2 col-md-3 col-sm-12 card-content-img">
        {image ? (
          image
        ) : (
          <div className="no-img-container">
            <Icon icon="user" />
          </div>
        )}
      </div>
      <div className="col-lg-8 col-md-8 card-content-text">
        <CardTitle tag="h4">
          <UniversalLink href={item['@id']}>{title}</UniversalLink>
        </CardTitle>
        <CardText>
          {item.profilo_professionale && (
            <div>{item.profilo_professionale}</div>
          )}
          {item.incarico_rivestito && <div>{item.incarico_rivestito}</div>}
          {item.data_conclusione_incarico && (
            <div>
              <span className="label">Data conclusione incarico:</span>{' '}
              {fine_incarico}
            </div>
          )}
        </CardText>
        {item?.organizzazione_riferimento?.length > 0 && (
          <div className="structure-section">
            {item.organizzazione_riferimento.map((org) => (
              <UniversalLink href={org['@id']}>
                <Chip simple color="primary" className="mr-2">
                  <ChipLabel>{org.title}</ChipLabel>
                </Chip>
              </UniversalLink>
            ))}
          </div>
        )}
      </div>
      {(item.esami_ssn || item.attivita_professionale_privata) && (
        <div className="col-lg-2 col-md-12 person-card-link-more">
          <div className="mb-1 link-more-label">
            {intl.formatMessage(messages.prenota)}
          </div>
          {item.esami_ssn && (
            <div className="btn-container">
              <UniversalLink
                href="/come-fare-per/prenotazioni/punti_cup"
                className="book-link btn btn-primary"
              >
                {intl.formatMessage(messages.esami_ssn)}
              </UniversalLink>
            </div>
          )}
          {item.attivita_professionale_privata && (
            <div className="btn-container">
              <UniversalLink
                href="/come-fare-per/prenotazioni/attivita-professionale-privata-1"
                className="book-link btn btn-primary"
              >
                {intl.formatMessage(messages.libera_professione)}
              </UniversalLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const messages = defineMessages({
  prenota: {
    id: 'prenota',
    defaultMessage: 'Prenota',
  },
  esami_ssn: {
    id: 'esami_ssn',
    defaultMessage: 'Visite ed esami SSN',
  },
  libera_professione: {
    id: 'libera_professione',
    defaultMessage: 'Libera professione',
  },
});

export default CardPersonaGalliera;
